*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100vh;
}


/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
  .page-title{
    font-size: 3vmax;
    text-align: center;
    margin-bottom: 2vmax;
}
  
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){


}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
  .page-title{
    font-size: 2.5vmin;
    margin-bottom: 2vmin;
    text-align: center;
}
}