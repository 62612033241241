


/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    .modular-input-container{
        border: 0.35vmax solid black;
        padding: 1vmax 1.5vmax;
        border-radius: 0.75vmax;

    }

    .modular-input-label{
        padding-left: 1vmax;
        margin-bottom: 0.5vmax;
    }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
    .modular-input-container{
        border: 0.35vmax solid black;
        font-size: 2vmax;
        padding: 1vmax 1.5vmax;
        border-radius: 0.75vmax;
    }

    .modular-input-label{
        font-size: 1.75vmax;
    }

}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
    .modular-input-container{
        border: 0.35vmin solid black;
        padding: 1vmin 1.5vmin;
        border-radius: 1vmin;
        font-size: 2vmin;
        width: 35vmin;
    }

    .modular-input-label{
        font-size: 2vmin;
    }
}