
/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){


}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
    .page-title{
        font-size: 2.5vmax;
    }
}