
/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    .confirm-page-container{
        display: flex;
        width: 85vw;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){

    .confirm-page-container{
        width: 75vw;
        font-size: 2.5vmax;
    }
}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
    .confirm-page-container{
        font-size: 2.5vmin;
        width: 50vw;
        text-align: center;
    }
}