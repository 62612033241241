.modular-button-container:hover{
  cursor: pointer;
}


/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
  .modular-button-container{
     border: 0.35vmax solid black;
     padding: 0.75vmax 1.5vmax;
     border-radius: 0.75vmax;
     margin-bottom: 2vmax;
  }

  .selection-advance{
    text-align: center;
    width: 80vw;
  }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
  .modular-button-container{
    border: 0.35vmax solid black;
    padding: 0.75vmax 1.5vmax;
    border-radius: 0.75vmax;
    margin-bottom: 2vmax;
    font-size: 2vmax;
    font-weight: 500;
 }
  .selection-advance{
    text-align: center;
    width: 70vw;
  }
}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
  .modular-button-container{
    border: 0.35vmin solid black;
    padding: 1vmin 1.5vmin;
    border-radius: 1vmin;
    margin-bottom: 2vmax;
    text-align: center;
    width: 30vmin;
    font-size: 2vmin;
 }

 .selection-advance{
    width: 35vw;
  }

}