.gameboard-container{
    border: 0.5vmax solid black;
    
}

/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){


    .gameboard-container{
        border-radius: 1vmax;
        height: 60vmax;
        position: relative;
        width: 80vw;
        margin-bottom: 2vmax;
    }

    .instruction-text{
        font-size: 2vmax;
        margin-bottom: 3vmax;
        text-align: center;
        width: 80vw;
    }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
    .gameboard-container{
        border-radius: 1vmax;
        height: 60vmax;
        position: relative;
        width: 60vw;
    }

    .instruction-text{
        width: 70vw;
    }

}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){

    .gameboard-container{
        border-radius: 2vmin;
        height: 65vmin;
        position: relative;
        width: 35vw;
        margin-bottom: 2vmin;
    }

    .instruction-text{
        font-size: 2vmin;
        margin-bottom: 3vmin;
        text-align: center;
        width: 35vw;
    }
}