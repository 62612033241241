.binary-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    .path-container{
        width: 60vmin;
    }
    .path-title{
        margin-bottom: 10vh;
        width: 90vw;
    }
    .path-button{
        width: 25vmin;
        text-align: center;
    }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
    .path-container{
        width: 65vw;
    }
    .path-title{
        margin-bottom: 10vh;
        width: 85vw;
    }
    .path-button{
        width: 25vmin;
        text-align: center;
    }
}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
    .path-container{
        width:40vw;
    }
}