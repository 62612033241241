.selection-checkbox{
    background-color: lightgrey;
}

.selected{
    background-color: limegreen;

}


.scroll-limit{
    overflow: hidden;
}

.selection-option:hover{
    cursor: pointer;
}

.suggested{
    background-color: dodgerblue;
    color: white;
}



/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){


    .selection-module-container{
        border-radius: 1vmax;
        height: 70vmax;
        position: relative;
        width: 80vw;
    }

    .selection-module{
        height: 60vmax;
        display: flex;
        justify-content: space-between;
        position: absolute;
        transition: left ease 0.5s;
        left: 0;
    }

    .selection-heading{
        margin-top: 2vmax;
        margin-bottom: 2vmax;
        font-weight: 600;
    }

    .selection-slide{
        border-radius: 1vmax;
        border: 0.35vmax solid black;
        padding: 1.5vmax 3vmax;
        background-color: white;
        height: 100%;
        overflow-y: scroll;
        width: 80vw;
        margin-right: 10vw;
        position: relative;
        margin-bottom: 2vmax;
    }

    .selection-option{
        border-radius: 1vmax;
        border: 0.35vmax solid black;
        padding: 1vmax 1.5vmax;
        padding-top: 2vmax;
        margin-bottom: 2vmax;
    }

    .selection-option-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2vmax;
    }

    .selection-title{
        width: 75%;
        font-weight: 700;
    }

    .selection-caption{
        font-weight: 500;
    }

    .selection-checkbox{
        height: 2.5vmax;
        width: 2.5vmax;
        border-radius: 0.25vmax;
        border: 0.15vmax solid black;
    }


    .selection-description{
        font-size: 1.5vmax;
        font-weight: 500;
        margin-bottom: 1vmax;
    }

    .selection-list{
        margin-left: 2vmax;
        margin-bottom: 1vmax;

    }

    .selection-list-item{
        font-size: 1.5vmax;
        font-weight: 500;
    }

    .selection-option-bottom{
        height: 0vh;
        transition: height ease 0.5s;
        overflow: hidden;
    }

    .slide-header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5vmax;
        margin-bottom: 2vmax;
    }

    .key-container{
        border: 0.35vmax solid black;
        border-radius: 0.75vmax;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 18vmax;
        padding: 0.5vmax 1.5vmax;
    }

    .open{
        height: 40vh;
    }

    .second-position{
        left: -90vw;
    }

    .third-position{
        left: -180vw;
    }

    .fourth-position{
        left: -270vw;
    }

    .fifth-position{
        left: -360vw;
    }


}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
    .selection-module-container{
        border-radius: 1vmax;
        height: 70vmax;
        position: relative;
        width: 70vw;
    }

    .selection-module{
        height: 60vmax;
        display: flex;
        justify-content: space-between;
        position: absolute;
        transition: left ease 0.5s;
        left: 0;
    }

    .selection-heading{
        margin-top: 2vmax;
        margin-bottom: 2vmax;
        font-weight: 600;
        font-size: 3vmax;
    }

    .selection-slide{
        border-radius: 1vmax;
        padding: 1.5vmax 3vmax;
        background-color: white;
        height: 100%;
        overflow-y: scroll;
        width: 70vw;
        margin-right: 10vw;
        position: relative;
        margin-bottom: 2vmax;
    }

    .selection-option{
        border-radius: 1vmax;
        padding: 1vmax 1.5vmax;
        padding-top: 2vmax;
        margin-bottom: 2vmax;
    }

    .selection-option-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2vmax;
    }

    .selection-title{
        width: 75%;
        font-weight: 700;
        font-size: 2.5vmax;
    }

    .selection-caption{
        font-weight: 500;
        font-size: 2vmax;
    }

    .selection-checkbox{
        height: 2.5vmax;
        width: 2.5vmax;
        border: 0.1vmin solid black;    
    }


    .selection-description{
        font-size: 1.75vmax;
        font-weight: 500;
        margin-bottom: 1vmax;
    }

    .selection-list{
        margin-left: 2vmax;
        margin-bottom: 1vmax;
        
    }

    .selection-list-item{
        font-size: 1.75vmax;
        font-weight: 500;
    }

    .selection-option-bottom{
        height: 0vh;
        transition: height ease 0.5s;
        overflow: hidden;
    }

    .slide-header-container{
        padding: 0 1.5vmax;
        margin-bottom: 2vmax;
        font-size: 2vmax;
    }

    .key-container{
        border: 0.35vmax solid black;
        border-radius: 0.75vmax;
        width: 18vmax;
        padding: 0.75vmax 1.5vmax;
    }

    .open{
        height: 45vh;
    }

    .second-position{
        left: -80vw;
    }

    .third-position{
        left: -160vw;
    }

    .fourth-position{
        left: -240vw;
    }

    .fifth-position{
        left: -320vw;
    }

}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){

    .selection-module-container{
        border-radius: 1vmin;
        height: 80vmin;
        position: relative;
        width: 35vw;
    }

    .selection-module{
        height: 70vmin;
        display: flex;
        justify-content: space-between;
        position: absolute;
        transition: left ease 0.5s;
        left: 0;
    }

    .selection-heading{
        margin-top: 2vmin;
        margin-bottom: 2vmin;
        font-weight: 700;
        font-size: 3.5vmin;
    }

    .selection-slide{
        border-radius: 1vmin;
        border: 0.35vmin solid black;
        padding: 1.5vmin 3vmin;
        background-color: white;
        height: 100%;
        overflow-y: scroll;
        width: 35vw;
        margin-right: 10vw;
        position: relative;
        margin-bottom: 2vmin;
    }

    .selection-option{
        border-radius: 1vmin;
        border: 0.35vmin solid black;
        padding: 1vmin 2.5vmin;
        padding-top: 2vmin;
        margin-bottom: 2vmin;
    }

    .selection-option-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2vmin;
    }

    .selection-title{
        width: 75%;
        font-weight: 700;
        font-size: 3vmin;
    }

    .selection-caption{
        font-weight: 700;
        font-size: 2.5vmin;
    }

    .selection-checkbox{
        height: 3vmin;
        width: 3vmin;
        border-radius: 0.35vmin;
    }


    .selection-description{
        font-size: 2vmin;
        font-weight: 500;
        margin-bottom: 1vmin;
    }

    .selection-list{
        margin-left: 2vmin;
        margin-bottom: 1vmin;

    }

    .selection-list-item{
        font-size: 2vmin;
        font-weight: 500;
    }

    .selection-option-bottom{
        height: 0vh;
        transition: height ease 0.5s;
        overflow: hidden;
    }

    .slide-header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5vmin;
        margin-bottom: 2vmin;
        font-size: 2vmin;
    }

    .key-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0.35vmin solid black;
        border-radius: 0.75vmin;
        width: 18vmin;
        padding: 0.75vmin 1.5vmin;
    }

    .open{
        height: 50vh;
    }

    .second-position{
        left: -45vw;
    }

    .third-position{
        left: -90vw;
    }

    .fourth-position{
        left: -135vw;
    }

    .fifth-position{
        left: -180vw;
    }
}