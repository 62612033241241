

/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){

    .instruction-text{
        font-size: 2vmax;
        margin-bottom: 3vmax;
        text-align: center;
        width: 80vw;
    }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){

    .instruction-text{
        width: 70vw;
    }

}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){


    .instruction-text{
        font-size: 2vmin;
        margin-bottom: 3vmin;
        text-align: center;
        width: 35vw;
    }
}