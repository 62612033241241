.error-title{
    text-align: center;
}

/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    .error-title{
        margin-bottom: 2vmax;
    }
    
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){
.error-title{
    margin-bottom: 2vmax;
}

}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){
    .error-title{
        margin-bottom: 2vmin;
    }
}