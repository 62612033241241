.error-component-container{
    padding: 1vmax 1.5vmax;
    text-align: center;
}

.error-component-message{
    color: firebrick;
    margin-bottom: 1vmax;
}

.error-component-button{
    background-color: firebrick;
    border: none;
    color: white;
    border-radius: 0.5vmax;
    padding: 0.5vmax 2vmax;
}