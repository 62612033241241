
/* MOBILE / VERTICAL */
@media screen and (max-aspect-ratio: 1/1){
    .contact-page-inner-container{
        display: flex;
        width: 95%;
        flex-direction: column;
        align-items: center;
    }
    .temp-input{
        border: 2px solid black;
        padding: 0.5rem 1.25rem;
        margin: 2vmax 0;       
    }
}


/* TABLETS */

@media screen and (min-aspect-ratio: 9/16){

    .contact-page-inner-container{
        display: flex;
        width: 95%;
        flex-direction: column;
        align-items: center;
    }

    
}


/* DESKTOP / HORIZONTAL */

@media screen and (min-aspect-ratio: 1/1){

    .contact-page-inner-container{
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 80vmin;
        height: 60vmin;
        flex-wrap: wrap;
    }

    .drop-down-container{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }
}